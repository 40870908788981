import React from 'react'
// import styled from '@emotion/styled'

import Layout from '../../layouts/default'
import SEO from '../../components/seo'
import Container from '../../components/common/container'
import { Title } from '../../components/common/text'
import ProjectSearch from '../../components/projectSearch'

function ProjectSearchPage() {
  return (
    <Layout>
      <SEO title="Repositorio Virtual - Busqueda" />
      <Container variant="primary">
        <div className="container-fluid">
          <Title>Repositorio Virtual</Title>
          <ProjectSearch
            indexName={process.env.GATSBY_ALGOLIA_PROJECT_REPOSITORY_INDEX}
            suggestionsIndexName={
              process.env.GATSBY_ALGOLIA_PROJECT_REPOSITORY_SUGGESTIONS_INDEX
            }
            placeholder="Buscar proyecto..."
            searchUrl="/projects/search"
          />
        </div>
      </Container>
    </Layout>
  )
}

export default ProjectSearchPage
